div.grid-item-calendar-tt {
    padding: 15px 0px 0px 0px !important;
    width: 123%;
}
div.grid-container-tt {
    width: 123%;
    margin-left: -35px!important;
}
.border-bottom-tt {
    border-bottom: 10px solid #e2e2e2;
}
div.padding-top-0 {
    padding-top: 0px !important;
}